<template>
  <div class="myPage">
    <el-container class="normalCon">
      <el-main>
        <div class="listTable">
          <i class="el-icon-arrow-left topLogo" @click="back()"></i>
          <div class="tableSwiper">
            <img src="../assets/banner2.jpg" alt="">
          </div>
          <div class="introMain">
            <h5>佛山市灏海餐饮管理有限公司</h5>
            <div class="nameBlock">
              <p>地址:佛山市禅城区佛山大道中35号8楼</p>
            </div>
<!--            <div class="nameBlock">-->
<!--              <h5>电话号码</h5>-->
<!--              <p>0757-87823963</p>-->
<!--              <p>0757-87832066</p>-->
<!--            </div>-->
<!--            <div class="nameBlock">-->
<!--              <h5>投诉电话</h5>-->
<!--              <p>0757-87832282</p>-->
<!--            </div>-->
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.replace("Home");
    }
  },
}
</script>

<style scoped lang="scss">
.listTable {
  position: relative;
  background-color: #fff;
  width: 100%;
  .tableSwiper {
    position: relative;
    max-height: 30vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .introMain {
    position: relative;
    padding: 15px 15px 0;
    h5 {
      font-size: 1.4rem;
      padding: 10px 0 15px;
      font-weight: 600;
      text-align: center;
    }
    .nameBlock {
      h5 {
        font-size: 1.2rem;
        padding: 10px 0 15px;
        text-align: center;
        font-weight: 400;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.8;
        margin: 0 0 10px;
        text-align: center;
      }
    }

  }
}
</style>
